import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'

const TagsPage = ({ data }) => {
  return (
    <Layout pageTitle="My Tags">
      <ul>
        {
          data.allMdx.group.map((groupElement) => (
            <li>
                {groupElement.tag}
                {
                  groupElement.nodes.map((node) => (
                    <h2>
                      <Link to={`/blog/${node.slug}`}>
                        {node.frontmatter.title}
                      </Link>
                    </h2>               
                  ))
                }    

            </li>
          ))
        }
      </ul>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
        nodes {
          slug,
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default TagsPage